// 数据服务流程组件

import styles from './index.module.scss'

import { ReactSVG } from 'react-svg'

// 引入自定义SVG-ICON组件
import Text from '@/components/Text'

// 数据服务流程序列数组
const processes = [
  {
    icon: 'process_1.svg', // SVG图标
    title: '提出需求', // 文字描述
  },
  {
    icon: 'process_2.svg',
    title: '需求解析',
  },
  {
    icon: 'process_3.svg',
    title: '试标报价',
  },
  {
    icon: 'process_4.svg',
    title: '签订合同',
  },
  {
    icon: 'process_5.svg',
    title: '标注质检',
  },
  {
    icon: 'process_6.svg',
    title: '验收交付',
  },
  {
    icon: 'process_7.svg',
    title: '费用结算',
  },
]

function ListItem(props: any) {
  return (
    <span>
      <div className={styles.circle}>
        <ReactSVG src={require('@/assets/icons/process/' + props.icon).default} />
      </div>
      <div className={styles.text}>{props.title}</div>
    </span>
  )
}

function ProcessList(props: any) {
  const processes = props.processes
  const listItems = processes.map((process: any) => (
    <ListItem key={process.icon} icon={process.icon} title={process.title} />
  ))
  return <>{listItems}</>
}

export default function Process() {
  return (
    <>
      <Text title="数据服务流程" subtitle="Service Process" name='process'></Text>
      {/* 中间灰色的连接线 */}

      <div className={styles.divider}></div>
      <div className={styles.process}>
        <ProcessList processes={processes} />
      </div>
    </>
  )
}
