import styles from './index.m.module.scss'

import Text from '../Text/index.m'

export default function About() {
  return (
    <>
      <Text title="关于我们" subtitle="About us"></Text>
      <div className={styles.about}>
        壹来倍iEasyLabel是一家AI数据服务提供商，提供包括数据采集、数据标注服务以及标注工具平台。旨在彻底改革客户的AI技术栈的数据供给侧，提供高质量、低成本、安全合规的数据标注服务。
      </div>
    </>
  )
}
