import styles from './index.module.scss'

import { Form, Input, Button, message } from 'antd'

import axios from 'axios'

import { ReactSVG } from 'react-svg'

const { TextArea } = Input

export default function ContactUs(props: any) {

  const [form] = Form.useForm()
  function formSubmit(values: any) {
    let formData = form.getFieldsValue()
    axios
      .post('/email/send_email', {
        corporateName: formData.corporateName,
        uname: formData.uname,
        phone: formData.phone,
        requirement: formData.requirement,
      })
      .then(function (response) {
        let data = response.data
        if (data.code === 200) {
          props.history.push('/succ')
        } else {
          message.error(data.msg)
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  return (
    <div className={styles.contactUs}>
      <div className={styles.title}>
        <h1>联系我们</h1>
        <h2>填写您的联系方式，我们的业务专家将尽快与您联系</h2>
      </div>
      <Form
        form={form}
        name="contactForm"
        layout="vertical"
        className={styles.form}
      >
        <Form.Item
          label="公司名称"
          name="corporateName"
          rules={[{ required: true, message: '请输入您的公司名称' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="姓名"
          name="uname"
          rules={[{ required: true, message: '请输入您的姓名' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="联系电话"
          name="phone"
          rules={[{ required: true, message: '请输入您的联系电话' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="需求描述" name="requirement">
          <TextArea rows={5} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block onClick={formSubmit}>
            提交
          </Button>
        </Form.Item>
      </Form>
      <div className={styles.description}>
        <h1>
          <ReactSVG src={require('@/assets/icons/contact_1.svg').default} />
          获取高质量的机器学习数据服务
        </h1>
        <h2>我们的资深专家团队将为您提供高质量的数据标注服务</h2>
        <ul>
          <li>•&nbsp;图片数据标注</li>
          <li>•&nbsp;视频数据标注</li>
          <li>•&nbsp;点云数据标注</li>
          <li>•&nbsp;多传感器数据标注</li>
          <li>•&nbsp;音频数据标注（即将上线）</li>
          <li>•&nbsp;文本数据标注（即将上线）</li>
        </ul>
      </div>
    </div>
  )
}
