import React from 'react'

import { BrowserRouter as Router, Route } from 'react-router-dom'

import './App.css'

// 引入组件
import Index from './view/Index' // 首页
import Contact from './view/Contact' // 联系我们页面

// 移动端
import MIndex from './view-mobile/Index'
import MContact from './view-mobile/Contact'

// 邮件发送成功页面
import Succ from './view/Succ'


function App() {
  return (
    <Router>
      <div className="App">
        <Route exact path="/" component={Index}></Route>
        <Route exact path="/contact" component={Contact}></Route>
        <Route exact path="/m/" component={MIndex}></Route>
        <Route exact path="/m/contact" component={MContact}></Route>
        <Route exact path="/succ" component={Succ}></Route>
      </div>
    </Router>
  )
}

export default App
