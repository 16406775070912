import styles from './index.module.scss'

import { ReactSVG } from 'react-svg'

import { Button } from 'antd'

import { Link } from 'react-router-dom'

import QRCode from '@/assets/QRCode.png'

const scrollTo = (px: number) => {
  setTimeout(() => {
    window.scrollTo(0, px)
  })
}

function MenuList(props: any) {
  return (
    <ul className={styles.menu}>
      <li>
        <Link to="/">
          <Button type="text" onClick={() => scrollTo(800)}>
            数据服务
          </Button>
        </Link>
      </li>
      <li>
        <Link to="/" onClick={() => scrollTo(2420)}>
          <Button type="text">服务流程</Button>
        </Link>
      </li>
      <li>
        <Link to="/">
          <Button type="text" onClick={() => scrollTo(3220)}>
            关于我们
          </Button>
        </Link>
      </li>
      <li>
        <Link to="/contact">
          <Button type="text" onClick={() => scrollTo(0)}>
            联系我们
          </Button>
        </Link>
      </li>
    </ul>
  )
}

export default function FooterBar() {
  return (
    <div className={styles.footer}>
      <div className={styles.content}>
        <div>
          <ReactSVG src={require('@/assets/ieasylabel-logo.svg').default} />
          <div className={styles.info}>
            <div className={styles.QRCode}>
              <img src={QRCode} alt="" />
            </div>
            <div className={styles.desc}>
              <span>微信扫码二维码与我们沟通</span>
              <br />
              <span>或发送邮件至</span>
              <br />
              <span>contact@ieasylabel.com</span>
            </div>
          </div>
        </div>
        <MenuList />
      </div>
      <div className={styles.copyright}>
        <span>Copyright@壹来倍（上海）科技有限公司 | </span>
        <span>
          <a
            href="https://beian.miit.gov.cn/#/Integrated/index"
            rel="noopener noreferrer"
            target="_blank"
          >
            沪ICP备2021023857号-1
          </a>
        </span>
      </div>
    </div>
  )
}
