import styles from './index.m.module.scss'

import { Col, Row } from 'antd'

import { ReactSVG } from 'react-svg'

import Text from '../Text/index.m'


import img1 from '@/assets/images/industries/industries_1.png'
import img2 from '@/assets/images/industries/industries_2.png'
import img3 from '@/assets/images/industries/industries_3.png'
import img4 from '@/assets/images/industries/industries_4.png'
import img5 from '@/assets/images/industries/industries_5.png'
import img6 from '@/assets/images/industries/industries_6.png'


const data = [
  {
    id: 1,
    title: '自动驾驶',
    icon: 'industries_1.svg',
    img: img1,
    text: (
      <>
        <span>
          针对自动驾驶领域提供图片、点云及多传感器融合数据的离散或追踪标注；
        </span>
        <br />
        <span>
          支持的标注类型有：矩形、多边形、折线、2D立体框、图像语义分割、点云立体框、点云语音分割、融合标注等。
        </span>
      </>
    ),
  },
  {
    id: 2,
    title: '直播/短视频',
    icon: 'industries_2.svg',
    img: img2,
    text: (
      <>
        <span>针对直播/短视频领域提供图片或视频的标注服务；</span>
        <br />
        <span>支持的标注类型有：矩形、多边形、关键点、分类、转写等。</span>
      </>
    ),
  },
  {
    id: 3,
    title: '智慧工业',
    icon: 'industries_3.svg',
    img: img3,
    text: (
      <>
        <span>针对智慧工业领域提供图片或视频的标注服务；</span>
        <br />
        <span>支持的标注类型有：矩形、多边形、折线、关键点等。</span>
      </>
    ),
  },
  {
    id: 4,
    title: '智慧零售',
    icon: 'industries_4.svg',
    img: img4,
    text: (
      <>
        <span>针对智慧零售领域提供图片或视频的标注服务；</span>
        <br />
        <span>支持的标注类型有：矩形、多边形、语义分割、分类、转写等。</span>
      </>
    ),
  },
  {
    id: 5,
    title: '机器人',
    icon: 'industries_5.svg',
    img: img5,
    text: (
      <>
        <span>针对机器人领域提供图片、视频或音频的标注服务；</span>
        <br />
        <span>
          支持的标注类型有：矩形、多边形、分类、语音转写、语音合成等。
        </span>
      </>
    ),
  },
  {
    id: 6,
    title: '无人机',
    icon: 'industries_6.svg',
    img: img6,
    text: (
      <>
        <span>针对无人机领域航拍图片进行标注服务；</span>
        <br />
        <span>支持的标注类型有：矩形、多边形、语义分割、分类等。</span>
      </>
    ),
  },
]

function ListItem(props: any) {
  let data = props.data
  return (
    <Col lg={8} xs={24} className={styles.col}>
      <div className={styles.box}>
        <div className={styles.title}>{data.title}</div>
        <ReactSVG
          className={styles.svg}
          src={require('@/assets/icons/industries/' + data.icon).default}
        />
        <div className={styles.text}>{data.text}</div>
        <img src={data.img} alt="" />
      </div>
    </Col>
  )
}

function IndustriesList(props: any) {
  const list = props.list
  const listItems = list.map((item: any) => (
    <ListItem key={item.id} data={item} />
  ))
  return <Row>{listItems}</Row>
}

export default function Industries() {
  return (
    <>
      <Text title="服务行业" subtitle="Industries"></Text>
      <IndustriesList list={data} />
    </>
  )
}
