import { Result, Button } from 'antd'

import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function Index(props: any) {
  // 倒计时
  let [countDown, setCountDown] = useState(3)

  function setTimeoutCount() {
    if (countDown > 0)
      setTimeout(() => {
        setCountDown(v => v - 1)
      }, 1000)
    else props.history.push('/')
  }

  // 使用 useEffect 监听 countDown 变化
  useEffect(() => {
    setTimeoutCount()
  }, [countDown]) // eslint-disable-line 

  return (
    <Result
      status="success"
      title="发送成功，我们会在1个工作日内尽快与您联系"
      extra={[
        <Link to="/" key="back">
          <Button>返回首页</Button>
        </Link>,
        <span key="count">页面将在 {countDown} 秒后自动跳转至首页</span>,
      ]}
    />
  )
}
