import Text from '../Text/index.m'

import styles from './index.m.module.scss'

import { useState } from 'react'

import { Radio, Carousel } from 'antd'

import { ReactSVG } from 'react-svg'

import classNames from 'classnames'

// 计算机视觉
import rectangle_1 from '@/assets/images/rectangle_1.png'
import rectangle_2 from '@/assets/images/rectangle_2.png'
import rectangle_3 from '@/assets/images/rectangle_3.png'
import polygon_1 from '@/assets/images/polygon_1.png'
import polygon_2 from '@/assets/images/polygon_2.png'
import polygon_3 from '@/assets/images/polygon_3.png'
import polyline from '@/assets/images/polyline.png'
import keypoint_1 from '@/assets/images/keypoint_1.png'
import keypoint_2 from '@/assets/images/keypoint_2.png'
import boxgizmo_1 from '@/assets/images/boxgizmo_1.png'
import boxgizmo_2 from '@/assets/images/boxgizmo_2.png'
import segmentation_1 from '@/assets/images/segmentation_1.png'
import segmentation_2 from '@/assets/images/segmentation_2.png'
import sensor_fusion from '@/assets/images/sensor_fusion.png'
import classification_1 from '@/assets/images/classification_1.png'
import classification_2 from '@/assets/images/classification_2.png'
// 自然语言
import ocr from '@/assets/images/ocr.png'
import entity_identification from '@/assets/images/entity_identification.png'
import text_classification from '@/assets/images/text_classification.png'
// 语音
import phonetic_transcription from '@/assets/images/phonetic_transcription.png'
import tts from '@/assets/images/tts.png'
import phonetic_classification from '@/assets/images/phonetic_classification.png'
// 机器人
import r_rectangle from '@/assets/images/r_rectangle.png'
import r_polygon from '@/assets/images/r_polygon.png'
import r_trans from '@/assets/images/r_trans.png'
import r_tts from '@/assets/images/r_tts.png'
import r_classification from '@/assets/images/r_classification.png'

// 第一级
const listLevel1 = [
  {
    id: '1',
    name: '计算机视觉',
  },
  {
    id: '2',
    name: '自然语言',
  },
  {
    id: '3',
    name: '语音',
  },
  // {
  //   id: '4',
  //   name: '机器人',
  // },
]

// 第二级-计算机视觉
const listLevel2_1 = [
  {
    id: '1',
    svg: 'rectangle.svg',
    text: '矩形',
    checked: true,
  },
  {
    id: '2',
    svg: 'polygon.svg',
    text: '多边形',
  },
  {
    id: '3',
    svg: 'polyline.svg',
    text: '折线',
  },
  {
    id: '4',
    svg: 'keypoint.svg',
    text: '关键点',
  },
  {
    id: '5',
    svg: 'boxgizmo.svg',
    text: '立体框',
  },
  {
    id: '6',
    svg: 'segmentation.svg',
    text: '语义分割',
  },
  {
    id: '7',
    svg: 'sensor_fusion.svg',
    text: '多传感器融合',
  },
  {
    id: '8',
    svg: 'classification.svg',
    text: '分类',
  },
]

// 第二级-自然语言
const listLevel2_2 = [
  {
    id: '1',
    svg: 'ocr.svg',
    text: 'OCR转写',
    checked: true,
  },
  {
    id: '2',
    svg: 'entity_identification.svg',
    text: '实体识别',
  },
  {
    id: '3',
    svg: 'classification.svg',
    text: '分类',
  },
]
// 第二级-语音
const listLevel2_3 = [
  {
    id: '1',
    svg: 'phonetic_transcription.svg',
    text: '语音转写',
    checked: true,
  },
  {
    id: '2',
    svg: 'tts.svg',
    text: '语音合成',
  },
  {
    id: '3',
    svg: 'classification.svg',
    text: '语音分类',
  },
]
// 第二级-机器人
const listLevel2_4 = [
  {
    id: '1',
    svg: 'rectangle.svg',
    text: '矩形',
    checked: true,
  },
  {
    id: '2',
    svg: 'polygon.svg',
    text: '多边形',
  },
  {
    id: '3',
    svg: 'phonetic_transcription.svg',
    text: '语音转写',
  },
  {
    id: '4',
    svg: 'tts.svg',
    text: '语音合成',
  },
  {
    id: '5',
    svg: 'classification.svg',
    text: '语音分类',
  },
]

// 第三季-轮播组件
const listLevel3_1_1 = [
  {
    id: '1',
    img: rectangle_1,
  },
  {
    id: '2',
    img: rectangle_2,
  },
  {
    id: '3',
    img: rectangle_3,
  },
]
const listLevel3_1_2 = [
  {
    id: '1',
    img: polygon_1,
  },
  {
    id: '2',
    img: polygon_2,
  },
  {
    id: '3',
    img: polygon_3,
  },
]
const listLevel3_1_3 = [
  {
    id: '1',
    img: polyline,
  },
]
const listLevel3_1_4 = [
  {
    id: '1',
    img: keypoint_1,
  },
  {
    id: '2',
    img: keypoint_2,
  },
]
const listLevel3_1_5 = [
  {
    id: '1',
    img: boxgizmo_1,
  },
  {
    id: '2',
    img: boxgizmo_2,
  },
]
const listLevel3_1_6 = [
  {
    id: '1',
    img: segmentation_1,
  },
  {
    id: '2',
    img: segmentation_2,
  },
]
const listLevel3_1_7 = [
  {
    id: '1',
    img: sensor_fusion,
  },
]
const listLevel3_1_8 = [
  {
    id: '1',
    img: classification_1,
  },
  {
    id: '2',
    img: classification_2,
  },
]
const listLevel3_2_1 = [
  {
    id: '1',
    img: ocr,
  },
]
const listLevel3_2_2 = [
  {
    id: '1',
    img: entity_identification,
  },
]
const listLevel3_2_3 = [
  {
    id: '1',
    img: text_classification,
  },
]
const listLevel3_3_1 = [
  {
    id: '1',
    img: phonetic_transcription,
  },
]
const listLevel3_3_2 = [
  {
    id: '1',
    img: tts,
  },
]
const listLevel3_3_3 = [
  {
    id: '1',
    img: phonetic_classification,
  },
]
const listLevel3_4_1 = [
  {
    id: '1',
    img: r_rectangle,
  },
]
const listLevel3_4_2 = [
  {
    id: '1',
    img: r_polygon,
  },
]
const listLevel3_4_3 = [
  {
    id: '1',
    img: r_trans,
  },
]
const listLevel3_4_4 = [
  {
    id: '1',
    img: r_tts,
  },
]
const listLevel3_4_5 = [
  {
    id: '1',
    img: r_classification,
  },
]

export default function Sever() {
  const [level1, setLevel1] = useState('1')
  // const [level2, setLevel2] = useState('1')
  const [listLevel2Value, setListLevel2Value] = useState(listLevel2_1)
  const [listLevel3Value, setListLevel3Value] = useState(listLevel3_1_1)
  // 第一级的按钮发生改装
  const severChange = (e: any) => {
    switch (e.target.value) {
      case '1':
        setListLevel2Value(listLevel2_1)
        break
      case '2':
        setListLevel2Value(listLevel2_2)
        break
      case '3':
        setListLevel2Value(listLevel2_3)
        break
      case '4':
        setListLevel2Value(listLevel2_4)
        break
    }
    setLevel1(e.target.value)
    // setLevel2('1')
    setListLevel2Value(val => {
      val.forEach(val => {
        val.checked = false
      })
      val[0].checked = true
      return [...val]
    })
    level3Change(e.target.value, '1')
  }
  const ListLevel1 = listLevel1.map(item => (
    <Radio.Button key={item.id} value={item.id}>
      {item.name}
    </Radio.Button>
  ))
  // 第二级的按钮发生改变
  const typeChange = (id: any) => {
    setListLevel2Value(val => {
      val.forEach(val => {
        val.checked = false
      })
      val[id - 1].checked = true
      return [...val]
    })
    // setLevel2(id)
    level3Change(level1, id)
  }
  function level3Change(level1: any, level2: any) {
    switch (level1) {
      case '1':
        switch (level2) {
          case '1':
            setListLevel3Value(listLevel3_1_1)
            break
          case '2':
            setListLevel3Value(listLevel3_1_2)
            break
          case '3':
            setListLevel3Value(listLevel3_1_3)
            break
          case '4':
            setListLevel3Value(listLevel3_1_4)
            break
          case '5':
            setListLevel3Value(listLevel3_1_5)
            break
          case '6':
            setListLevel3Value(listLevel3_1_6)
            break
          case '7':
            setListLevel3Value(listLevel3_1_7)
            break
          case '8':
            setListLevel3Value(listLevel3_1_8)
            break
        }
        break
      case '2':
        switch (level2) {
          case '1':
            setListLevel3Value(listLevel3_2_1)
            break
          case '2':
            setListLevel3Value(listLevel3_2_2)
            break
          case '3':
            setListLevel3Value(listLevel3_2_3)
            break
        }
        break
      case '3':
        switch (level2) {
          case '1':
            setListLevel3Value(listLevel3_3_1)
            break
          case '2':
            setListLevel3Value(listLevel3_3_2)
            break
          case '3':
            setListLevel3Value(listLevel3_3_3)
            break
        }
        break
      case '4':
        switch (level2) {
          case '1':
            setListLevel3Value(listLevel3_4_1)
            break
          case '2':
            setListLevel3Value(listLevel3_4_2)
            break
          case '3':
            setListLevel3Value(listLevel3_4_3)
            break
          case '4':
            setListLevel3Value(listLevel3_4_4)
            break
          case '5':
            setListLevel3Value(listLevel3_4_5)
            break
        }
        break
    }
  }
  function ListLevel2(props: any) {
    const items = props.list
    const tmpList = items.map((item: any) => (
      <li key={item.id}>
        <button onClick={() => typeChange(item.id)}>
          <ReactSVG src={require('@/assets/icons/sever/' + item.svg).default} />
          <span className={classNames(item.checked ? styles.checked : '')}>
            {item.text}
          </span>
        </button>
      </li>
    ))
    return tmpList
  }
  function ListLevel3(props: any) {
    const items = props.list
    const tmpList = items.map((item: any) => (
      <div key={item.id}>
        <img src={item.img} alt="" />
      </div>
    ))
    return (
      <Carousel autoplay className={styles.carousel}>
        {tmpList}
      </Carousel>
    )
  }

  return (
    <>
      <Text title="高质量的数据服务" subtitle="Data Service"></Text>
      <Radio.Group
        defaultValue="1"
        className={styles.buttonList}
        size="large"
        onChange={severChange}
      >
        {ListLevel1}
      </Radio.Group>
      <div className={styles.level2}>
        <ul className={styles.ul}>
          <ListLevel2 list={listLevel2Value} />
        </ul>
      </div>

      <ListLevel3 list={listLevel3Value} />
    </>
  )
}
