import styles from './index.m.module.scss'

import QRCode from '@/assets/QRCode.png'

// 联系邮箱
const email = 'contact@ieasylabel.com'



export default function FooterBar() {
  return (
    <div className={styles.footer}>
      <div className={styles.content}>
        <div className={styles.left}>
          <div className={styles.QRCode}>
            <img src={QRCode} alt="" />
          </div>
          <div>
            <span>长按保存用微信识别二维码，与我们沟通</span>
            <br />
            <span>或发送邮件至</span>
            <br />
            <span>{email}</span>
          </div>
        </div>
      </div>
      <div className={styles.copyright}>
      <span>Copyright@壹来倍（上海）科技有限公司</span>
            <br />
            <span><a href="https://beian.miit.gov.cn/#/Integrated/index" rel="noopener noreferrer" target="_blank">沪ICP备2021023857号-1</a></span>
        </div>
    </div>
  )
}
