import Container from '@/components/Container/index.m'
import TopBar from '@/components/TopBar/index.m'
import Main from '@/components/Main/index.m'
import Sever from '@/components/Sever/index.m'
import Industries from '@/components/Industries/index.m'
import Choose from '@/components/Choose/index.m'
import About from '@/components/About/index.m'
import Customers from '@/components/Customers/index.m'
import FooterBar from '@/components/FooterBar/index.m'
import Footer from '@/components/Footer/index.m'

import '@/styles/index.m.scss'
import '@/styles/override.m.scss'

export default function Contact() {
  return (
    <>
      <div className="header">
        <Container>
          <TopBar logoColor="white" menuHidden={false} />
          <Main />
        </Container>
      </div>
      <Sever />
      <Industries />
      <Container>
        <Choose />
        <About />
        <Customers />
      </Container>
      <div className="footerBar">
        <FooterBar />
      </div>
      <div className="footer">
        <Container>
          <Footer />
        </Container>
      </div>
    </>
  )
}
