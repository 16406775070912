import styles from './index.module.scss'

interface Text {
  title: string
  subtitle: string
  name?: string
}

export default function Title(props: Text) {
  return (
    <div className={styles.text} id={props.name}>
      <span className={styles.title}>{props.title}</span>
      <br />
      <span className={styles.subtitle}>{props.subtitle}</span>
    </div>
  )
}
