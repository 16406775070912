import styles from './index.module.scss'

import Text from '../Text'

import logo1 from '@/assets/customers/logo_1.png'
import logo2 from '@/assets/customers/logo_2.png'
import logo3 from '@/assets/customers/logo_3.png'
import logo4 from '@/assets/customers/logo_4.png'
import logo5 from '@/assets/customers/logo_5.png'
import logo6 from '@/assets/customers/logo_6.png'

const customers = [
  {
    id: 1,
    logo: logo1,
  },
  {
    id: 2,
    logo: logo2,
  },
  {
    id: 3,
    logo: logo3,
  },
  {
    id: 4,
    logo: logo4,
  },
  {
    id: 5,
    logo: logo5,
  },
  {
    id: 6,
    logo: logo6,
  },
]

function ListItem(props: any) {
  return (
    <div className={styles.logo}>
      <img src={props.logo} alt="" />
    </div>
  )
}

function CustomersList(props: any) {
  const customers = props.customers
  const listItems = customers.map((customer: any) => (
    <ListItem key={customer.id} logo={customer.logo} />
  ))
  return <>{listItems}</>
}

export default function Industries() {
  return (
    <>
      <Text title="合作伙伴" subtitle="Partners"></Text>
      <div className={styles.box}>
        <CustomersList customers={customers} />
      </div>
    </>
  )
}
