import Container from '@/components/Container/index.m'
import TopBar from '@/components/TopBar/index.m'
import ContactUs from '@/components/ContactUs/index.m'
import FooterBar from '@/components/FooterBar/index.m'
import Footer from '@/components/Footer/index.m'

// 引入css样式
import '@/styles/index.m.scss'
// import '@/styles/override.m.scss'

export default function Contact(props: any) {
  return (
    <>
      <Container>
        <TopBar logoColor="main" menuHidden={true} />
      </Container>
        <Container>
          <ContactUs history={props.history}/>
        </Container>
      <div className="footerBar">
        <Container>
          <FooterBar />
        </Container>
      </div>
      <div className="footer">
        <Container>
          <Footer />
        </Container>
      </div>
    </>
  )
}
