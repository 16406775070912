import Container from '@/components/Container'
import Main from '@/components/Main'
import TopBar from '@/components/TopBar'
import Sever from '@/components/Sever'
import Industries from '@/components/Industries'
import Process from '@/components/Process'
import Choose from '@/components/Choose'
import About from '@/components/About'
import Customers from '@/components/Customers'
import FooterBar from '@/components/FooterBar'
import Footer from '@/components/Footer'

// 引入自定义css
import '@/styles/index.scss'
import '@/styles/override.scss'

export default function Index(props: any) {
  // 根据访问设备进行响应
  const ua = navigator.userAgent
  if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(ua)) {
    props.history.push('/m/')
  }
  return (
    <>
      {/* 此header是为了添加背景颜色 */}
      <div className="header">
        <Container>
          <TopBar />
          <Main />
        </Container>
      </div>
      <Container>
        <Sever />
        <Industries />
        <Process />
        <Choose />
        <About />
        <Customers />
      </Container>
      <div className="footerBar">
        <Container>
          <FooterBar />
        </Container>
      </div>
      <div className="footer">
        <Container>
          <Footer />
        </Container>
      </div>
    </>
  )
}
