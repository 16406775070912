import Container from '@/components/Container'
import TopBar from '@/components/TopBar'
import ContactUs from '@/components/ContactUs'
import FooterBar from '@/components/FooterBar'
import Footer from '@/components/Footer'

// 引入css样式
import '@/styles/index.scss'

export default function Contact(props: any) {
  return (
    <>
      <Container>
        <TopBar logoColor="contact" menuHidden={true} />
      </Container>
      <div className="contact">
        <Container>
          <ContactUs history={props.history}/>
        </Container>
      </div>
      <div className="footerBar">
        <Container>
          <FooterBar />
        </Container>
      </div>
      <div className="footer">
        <Container>
          <Footer />
        </Container>
      </div>
    </>
  )
}
