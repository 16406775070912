// main 导航条下方的区域

import { Button } from 'antd'

import { Link } from 'react-router-dom'

import styles from './index.m.module.scss'

// 引入图片
import img from '@/assets/diamond_m.png'

// 文字内容
const desc = {
  title: '用高质量数据构建成功AI',
  subTitle: '壹来倍提供专业AI训练数据服务，全流程提供视频图像、雷达、文本、语音等多种类型数据的采集、标注与维护，为您的AI铺路搭桥',
  buttonText: '联系我们',
}

export default function Main() {
  return (
    <div className={styles.main}>
      <div className={styles.desc}>
        <h1>{desc.title}</h1>
        <h2>{desc.subTitle}</h2>
        <Link to="/m/contact">
          <Button size='large' type="primary">{desc.buttonText}</Button>
        </Link>
      </div>
      <div className={styles.img}>
        <img src={img} alt="" />
      </div>
    </div>
  )
}
