// 为什么选择我们组件

import styles from './index.m.module.scss'

import { List } from 'antd'

import { ReactSVG } from 'react-svg'

import Text from '../Text/index.m'

const data = [
  {
    icon: 'choose_1.svg',
    title: '安全合规',
    subtitle:
      '通过数据隔离、权限分离、人员合规和私有化部署等管控机制保障数据安全',
  },
  {
    icon: 'choose_2.svg',
    title: '质量保障',
    subtitle:
      '从审核流程、算法驱动的工具、人员管理三个方面保障标注的质量，准确率最高达99.99%',
  },
  {
    icon: 'choose_3.svg',
    title: '规模化',
    subtitle:
      '通过完善的项目流程和数据监控机制来管理我们上千个标注供应商团队，满足客户规模化的数据需求',
  },
  {
    icon: 'choose_4.svg',
    title: '成本控制',
    subtitle:
      '通过算法预标注、专业标注工具、算法辅助标注、精细化的人员效率管理，降低标注成本',
  },
]

export default function Choose() {
  return (
    <>
      <Text title="为什么选择我们" subtitle="Why us"></Text>
      <List
        grid={{ gutter: 16, column: 1 }}
        dataSource={data}
        renderItem={item => (
          <List.Item>
            <div className={styles.item}>
              <div className={styles.icon}>
                <ReactSVG
                  src={require('@/assets/icons/choose/' + item.icon).default}
                />
              </div>
              <div className={styles.text}>
                <span className={styles.title}>{item.title}</span>
                {/* <br /> */}
                <span className={styles.subtitle}>{item.subtitle}</span>
              </div>
            </div>
          </List.Item>
        )}
      />
    </>
  )
}
