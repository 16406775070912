import styles from './index.m.module.scss'

export default function Title(props: any) {
  return (
    <div className={styles.text}>
      <span className={styles.title}>{props.title}</span>
      <br />
      <span className={styles.subtitle}>{props.subtitle}</span>
    </div>
  )
}
