// topbar 页面顶部的导航条

import { Space, Button } from 'antd'

import { Link } from 'react-router-dom'

import classNames from 'classnames'

import { ReactSVG } from 'react-svg'

// css
import styles from './index.module.scss'

const scrollTo = (px: number) => {
  window.scrollTo(0, px)
}

export default function TopBar(props: any) {
  return (
    <div className={styles.topBar}>
      <Link to="/">
        <ReactSVG
          src={require('@/assets/ieasylabel-logo.svg').default}
          className={classNames(
            props.logoColor === 'contact' ? 'logo-contact' : '',
          )}
        />
      </Link>
      <Space
        align="end"
        size={30}
        className={classNames(
          props.menuHidden === true ? styles.menuHidden : '',
        )}
      >
        <Button type="text" onClick={() => scrollTo(800)}>
          数据服务
        </Button>
        <Button type="text" onClick={() => scrollTo(2420)}>
          服务流程
        </Button>
        <Button type="text" onClick={() => scrollTo(3220)}>
          关于我们
        </Button>
        <Link to="/contact">
          <Button type="primary">联系我们</Button>
        </Link>
      </Space>
    </div>
  )
}
