import { Link } from 'react-router-dom'
import styles from './index.m.module.scss'

// footerbar左侧的文字描述
const data = {
  text: '用高质量数据构建成功AI',
}

const scrollTo = (px: number) => {
  window.scrollTo(0, px)
}

export default function FooterBar() {
  return (
    <div className={styles.footerBar}>
      <div className={styles.text}>{data.text}</div>
      <Link to="/m/contact">
        <button
          type="button"
          className={styles.button}
          onClick={() => scrollTo(0)}
        >
          联系我们
        </button>
      </Link>
    </div>
  )
}
