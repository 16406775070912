// topbar 页面顶部的导航条

import { Button } from 'antd'

import { Link } from 'react-router-dom'

import classNames from 'classnames'

import { ReactSVG } from 'react-svg'

// css
import styles from './index.m.module.scss'

export default function TopBar(props: any) {
  return (
    <div className={styles.topBar}>
      <Link to="/m/">
        <ReactSVG
          src={require('@/assets/ieasylabel-logo.svg').default}
          className={classNames({ [`logo-${props.logoColor}`]: true })}
        />
      </Link>
      <Link to="/m/contact">
        <Button type="primary" className={classNames(props.menuHidden === true ? styles.menuHidden : '')}>联系我们</Button>
      </Link>
    </div>
  )
}
